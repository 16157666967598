import { MachineNotFoundError } from '@internal/utils/errors/machineErrors'
import { Machine } from '@internal/utils/machine/Machine'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import axios, { AxiosError } from 'axios'
import { environment } from 'environment/environment'
import { getAxiosData } from 'utils/api/getAxiosData'

const apiClient = axios.create({
  baseURL: '',
  timeout: 30000,
})

export type MachineMeta = Pick<
  Machine,
  'machineGroupId' | 'brand' | 'name' | 'machineType' | 'model' | 'pdp'
> & {
  mappedModel: string | null
  rawModel: string | null
}

export const getMachineMeta = async (
  machineGroupId: string,
  options?: {
    resolveMerged?: boolean
  }
) =>
  apiClient
    .get<MachineMeta>(
      `${
        environment.OWN_URL
      }/api/getMachineMeta/?machineGroupId=${machineGroupId}${
        options?.resolveMerged ? '&resolveMerged=true' : ''
      }`
    )
    .then(getAxiosData)
    .catch((err) => {
      const axiosError = err as AxiosError
      if (axiosError.isAxiosError && axiosError.response?.status === 404) {
        throw new MachineNotFoundError(machineGroupId)
      }
      throw err
    })

export const getSerpUrlForAlternatives = async (params: {
  noCache?: boolean
  refreshCache?: boolean
  locale: TLocale
  machine: MachineMeta
  ip?: string
}) =>
  apiClient
    .post<{ url: string }>(`/api/getSerpUrlForAlternatives/`, params)
    .then(getAxiosData)
