import { getMachineMeta } from '@internal/services/apiClient'
import {
  FinalMachineMergeResultNotReachedError,
  MachineNotFoundError,
} from '@internal/utils/errors/machineErrors'
import { getSSRMachineTypeName } from 'i18n/getSSRMachineTypeName'
import { SystemPageKey } from 'page/SystemPageKey'
import { buildMachineUrlPathParams } from 'utils/routing/buildMachineUrlPathParams'
import { urlFor } from '../urls/urlFor'
import { TRedirectGenerator } from './TRedirectGenerator'

export const getRedirectForMachine: TRedirectGenerator<{
  machineGroupId?: string
}> = async ({ pathParams, locale, queryParams }) => {
  const machineGroupId = pathParams.machineGroupId
  if (!machineGroupId) {
    return false
  }

  try {
    /**
     * because this code runs on the server in the edge runtime (or at least next thinks that at build time)
     * we cannot consume the authenticatedMachineSearchClient
     * in order to work around that we call an internal api that does the call to the machine search service
     */

    const machine = await getMachineMeta(machineGroupId, {
      resolveMerged: true,
    })

    if (!machine?.pdp) {
      return false
    }

    return urlFor(locale, {
      key: SystemPageKey.PDP,
      pathParams: {
        ...buildMachineUrlPathParams(locale, {
          machineGroupId: machine.machineGroupId,
          brand: machine.brand,
          model: machine.model,
          machineTypeName: getSSRMachineTypeName(
            locale,
            machine.machineType,
            'plural'
          ),
        }),
      },
      queryParams,
    })
  } catch (err) {
    if (
      !(
        err instanceof MachineNotFoundError ||
        err instanceof FinalMachineMergeResultNotReachedError
      )
    ) {
      console.log(
        `Error in getRedirectForMachine ${
          'message' in (err as Error) ? (err as Error).message : err
        }`
      )
    }

    return false
  }
}
